import React, { useState } from 'react';
import Link from 'next/link';

const CategoryList = ({ categories }) => {
  return (
    <section className="md:grid md:grid-cols-3 ">
      {categories.map((category, index) => (
        <section key={index}>
          <button type="button" className="mb-3 cursor-pointer text-affes-sm text-blue-900 md:font-bold">
            <Link href={category.url}>{category.categoryName}</Link>
          </button>
          <ul className="mb-4 hidden md:block">
            {category.children.slice(0, 5).map((subCategory, subIndex) => (
              <li key={subIndex} className="mb-2 text-affes-sm text-gray-900">
                <Link href={subCategory.url}>{subCategory.categoryName}</Link>
              </li>
            ))}
            {category?.children?.length > 5 && (
              <div className="pb-2">
                <Link href={category?.url}>
                  <a className=" cursor-pointer text-affes-sm text-[#043C63]">See More</a>
                </Link>
              </div>
            )}
          </ul>
        </section>
      ))}
    </section>
  );
};

export default CategoryList;
