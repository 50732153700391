import React from 'react';
import Accordion from './accordion';
import MobileAccordion from './mobileAccordion';
function MegaMenu({
  megaMenu,
  setcloseMenu,
  leftColumn,
  rightColumn,
  findStore,
  militaryStarData,
  militaryStarApply,
  militaryStarParentURL,
  weeklyAd,
  setmobileMenu,
  setStoresList,
  storeList,
  searchInput,
  setSearchInput,
  isPreviewDate,
  islargeDesktop,
  additionalPadding,
  setResetDefaultStore,
}) {
  const menuCategories = megaMenu?.categoryData?.dataSource?.root?.children;

  return (
    <>
      <section
        className={`z-11 absolute hidden h-[100vw] w-[calc(100%_-_50px)] max-w-[calc(1440px_-_50px)] md:block ${
          isPreviewDate && (islargeDesktop || additionalPadding) ? 'top-[112px]' : 'top-[66px]'
        }`}
      >
        <section className="block md:grid md:grid-cols-5">
          <Accordion menuCategories={menuCategories} closeMenu={setcloseMenu} />
        </section>
      </section>

      <section className="fixed left-0 top-0 z-20 block h-[100vh] w-11/12 overflow-y-auto bg-white md:hidden">
        <section
          className="fixed left-0 top-0 z-10 h-[100vh] w-full bg-black-900 opacity-70"
          onClick={() => setmobileMenu(false)}
        ></section>
        <MobileAccordion
          menuCategories={menuCategories}
          leftColumn={leftColumn}
          rightColumn={rightColumn}
          findStore={findStore}
          closeMenu={setmobileMenu}
          militaryStarData={militaryStarData}
          militaryStarApply={militaryStarApply}
          militaryStarParentURL={militaryStarParentURL}
          weeklyAd={weeklyAd}
          setStoresList={setStoresList}
          storeList={storeList}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          setResetDefaultStore={setResetDefaultStore}
        />
      </section>
    </>
  );
}

export default MegaMenu;
