import React, { useState } from 'react';
import Link from 'next/link';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import MilitaryStar from '../../../icons/military-star';

function MilitryStar({ militaryStarData, militaryStarApply, militaryStarParentURL }) {
  const [militaryStar, setMilitaryStar] = useState(false);
  const [activeItem, setActiveItem] = useState(false);
  const militaryParentURL = militaryStarParentURL?.links;
  const [toggleMilitryStar, setToggleMilitryStar] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useFormat({ name: 'common' });
  const handleChangeActive = () => {
    setActiveItem((previousArrow) => {
      return !previousArrow;
    });
  };
  return (
    <>
      <section
        className="relative order-5 flex text-black-900 md:hidden "
        onClick={() => {
          setMilitaryStar(!militaryStar);
          setToggleMilitryStar(!toggleMilitryStar);
          handleChangeActive();
        }}
      >
        <Link href={militaryParentURL}>
          <p className="flex w-full cursor-pointer items-center justify-between">
            <section className="flex">
              <span className="mr-3 w-8">
                <MilitaryStar className="mr-3" />
              </span>
              <section className="text-sm text-[#1E1E1E] md:hidden xl:flex">
                {formatMessage({ id: 'militaryStar', defaultMessage: 'Military Star' })}
              </section>
            </section>
          </p>
        </Link>
        <span className="ml-3 text-lg">
          {militaryStar ? (
            <MdOutlineKeyboardArrowDown className="text-xl text-gray-900" />
          ) : (
            <MdOutlineKeyboardArrowRight className="text-xl text-gray-900" />
          )}
        </span>
      </section>
      {toggleMilitryStar && (
        <section className="h-[250px] py-3 pl-12">
          {militaryStarData.map((data, id) => (
            <section key={id} className="mb-1 py-1 ">
              <Link href={data.links}>
                <p className="cursor-pointer  text-blue-900 underline underline-offset-1 hover:no-underline hover:underline-offset-0">
                  {data.header}
                </p>
              </Link>
            </section>
          ))}

          <section>
            <Link href={militaryStarApply.links}>
              <p className=" my-3 w-1/2  rounded-sm border border-blue-900 bg-blue-900  py-2  text-center text-white">
                {militaryStarApply.header}
              </p>
            </Link>
          </section>
        </section>
      )}
    </>
  );
}

export default MilitryStar;
