import React, { useState } from 'react';
import Link from 'next/link';
import { GrClose } from 'react-icons/gr';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import RightArrow from '../../../icons/menu-right-arrow';
import CategoryList from './accordiandata';

const Accordion = ({ menuCategories, closeMenu }) => {
  const menuCat = menuCategories;
  const [activeItem, setActiveItem] = useState(null);
  const [hideClose, setHideClose] = useState(true);
  const { formatMessage } = useFormat({ name: 'common' });
  const [isDivHidden, setIsDivHidden] = useState(true);

  const handleMouseLeave = (event) => {
    const liLeft = event.target.getBoundingClientRect().left;
    const mouseX = event.clientX;
    if (mouseX < liLeft) {
      setIsDivHidden(false);
      setActiveItem(null);
    } else {
      setIsDivHidden(true);
    }
  };

  return (
    <>
      <section className="custom-box-shadow-top-left z-20 w-full bg-white">
        <section className="relative">
          <h4 className="border-[rgba(0, 0, 0, 0.1)] hidden border-r p-3 text-affes-sm font-semibold uppercase text-black-900 md:block">
            {formatMessage({ id: 'shopByCategory', defaultMessage: 'SHOP BY CATEGORY' })}
          </h4>
          {hideClose && (
            <button type="button" className="absolute right-5 top-4 cursor-pointer" onClick={() => closeMenu(false)}>
              <GrClose />
            </button>
          )}
        </section>
        <ul className="border-b">
          {menuCat?.map((item, index) => (
            <li
              key={index}
              onMouseEnter={() => {
                setActiveItem(item);
                setHideClose(false);
                if (!activeItem) {
                  setIsDivHidden(true);
                }
              }}
              onMouseLeave={handleMouseLeave}
              className={`${
                item?.slug === activeItem?.slug && 'border-r-0 border-l-[5px] border-l-blue-900'
              } border-[rgba(0, 0, 0, 0.1)] flex cursor-pointer items-center justify-between border-t border-r border-l-[5px] border-l-white py-3 px-2 text-affes-sm  text-gray-900 hover:border-l-[5px] hover:border-r-white hover:border-l-blue-900 hover:font-bold hover:text-blue-900`}
            >
              <Link href={item?.url}>{item?.categoryName}</Link>
              {item?.slug === activeItem?.slug && <RightArrow />}
            </li>
          ))}
        </ul>
      </section>
      {activeItem && isDivHidden && (
        <section
          className={
            activeItem
              ? 'custom-box-shadow border-[rgba(0, 0, 0, 0.1)] relative z-40 w-[100%] border-r bg-white md:col-span-4'
              : 'custom-box-shadow border-[rgba(0, 0, 0, 0.1)] z-20 hidden w-[100%] border-r bg-white md:col-span-4'
          }
        >
          {!hideClose && (
            <button type="button" className="absolute right-3 top-4 cursor-pointer" onClick={() => closeMenu(false)}>
              <GrClose />
            </button>
          )}
          <section className={`col-span-4 py-3 px-7 md:grid md:grid-cols-4`}>
            <section className="md:col-span-3">
              <CategoryList categories={activeItem.children} />
            </section>

            <section className="col-span-1 md:grid-cols-1">
              <Link href={`/brands?N=${activeItem?.categoryId}`}>
                <p className="mb-3 cursor-pointer text-affes-sm text-blue-900 md:font-semibold">Shop All Brands</p>
              </Link>
              {activeItem?.cmsContent && (
                <section>
                  <div dangerouslySetInnerHTML={{ __html: activeItem?.cmsContent }} />
                </section>
              )}
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default Accordion;
